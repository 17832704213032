import React from 'react'
import styled from 'styled-components'
import Navbar from '../components/Navbar'
import Footer from '../sections/Footer'
import star from '../assets/star-orange.svg'
import ledger from '../assets/ledger.gif'

const Ledger = () => {
  return (
    <div>
      <Navbar />
      <LedgerSection>
        <LedgerContainer>
          <div>
            <Title>Ledger Manager.</Title>
            <h2>
              Open accounts, mint tokens, manage balances and define
              transactional rules.
            </h2>
            <a href="#description">suscribe now</a>
          </div>
          <img src={ledger} />
        </LedgerContainer>
        <BoxContainer>
          <h3>
            The ledger module allows users to generate fungible tokens, such as
            coins. It works in two different ways:
          </h3>
        </BoxContainer>
        <Features>
          <div>CUSTODIAL</div>
          <div>NON CUSTODIAL</div>
        </Features>
      </LedgerSection>
      <Footer />
    </div>
  )
}

const BoxContainer = styled.div`
  display: block;
  height: auto;
  max-width: 960px;
  padding: 30px 96px;
  @media only screen and (max-width: 750px) {
    padding: 30px;
  }
`

const LedgerSection = styled.section`
  height: auto;
  background: white;
  min-height: 700px;
  padding-top: 80px;
  @media only screen and (max-width: 700px) {
    min-height: 650px;
    padding: 60px 0 0 0;
  }
  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    color: #000000;
  }
`

const LedgerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 96px;
  @media only screen and (max-width: 750px) {
    padding: 30px;
    flex-direction: column-reverse;
  }
  img {
    max-width: 50%;
    @media only screen and (max-width: 750px) {
      max-width: 69%;
      align-self: flex-end;
    }
  }
  div {
    max-width: 700px;
    @media only screen and (max-width: 750px) {
      max-width: 90%;
      margin: auto;
    }
  }

  h2 {
    font-family: 'Poppins-Thin';
    font-size: 26px;
    line-height: 129%;
    font-feature-settings: 'ss02' on;
    color: #20201e;
    max-width: 500px;
    @media only screen and (max-width: 750px) {
      font-size: 16px;
    }
  }
  a {
    font-family: 'Poppins-Bold';
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ff6700;
    padding: 8px 45px;
    border: solid 2px #ff6700;
    display: inline-block;
    margin-top: 40px;
  }
`

const Title = styled.h1`
  padding-top: 150px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  font-size: 65px;
  line-height: 115%;
  max-width: 559px;
  color: #282828;
  @media only screen and (max-width: 750px) {
    font-size: 50px;
    max-width: 300px;
    padding: 0;
    margin-top: -100px;
  }
`

const Features = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 150px;
  height: 110px;
  border-top: solid 2px #ff6700;
  border-bottom: solid 2px #ff6700;
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 51px;
  color: #ff6700;

  @media only screen and (max-width: 1060px) {
    font-size: 20px;
    line-height: 103%;
    height: 60px;
  }
  @media only screen and (max-width: 750px) {
    font-size: 14px;
    line-height: 103%;
    flex-wrap: wrap;
  }
  div {
    height: 100%;
    padding 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  div:nth-child(2) {
    border-left: solid 2px #ff6700;
    @media only screen and (max-width: 750px) {
      min-width: 200px!important;
      border-right: 0;
      border-left: 0;
      border-bottom: solid 2px #ff6700;
    }
  }
`

export default Ledger
