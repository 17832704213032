import React from 'react'
import styled from 'styled-components'
import star from '../assets/star-orange.svg'
import { Link } from 'react-scroll'
import Form from '../components/Form'

const Hero = () => {
  return (
    <HeroSection>
      <HeroContainer>
        <div>
          <Title>
            Start <img alt="" src={star} />
            building with <span className="orange">build.</span>
          </Title>
          <h2>
            Use and combine Koibanx's platform modules to deploy Blockchain &
            Crypto products yourself.
          </h2>
          <Link
            to="description"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            +info
          </Link>
        </div>
        <div>
          <Form />
        </div>
      </HeroContainer>
    </HeroSection>
  )
}

const HeroSection = styled.section`
  height: auto;
  min-height: 550px;
  padding-top: 80px;
  background: linear-gradient(
    to top,
    #faff00 0%,
    #faff00 calc(100% - 373px),
    #ffffff calc(100% - 373px),
    #ffffff 100%
  );
  @media only screen and (max-width: 700px) {
    min-height: 650px;
    padding: 60px 0 0 0;
  }
`

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }
  div {
    max-width: 700px;
    @media only screen and (max-width: 750px) {
      max-width: 90%;
      margin: auto;
    }
  }

  h2 {
    font-family: 'Poppins';
    font-size: 21px;
    line-height: 31px;
    font-feature-settings: 'ss02' on;
    color: #000000;
    mix-blend-mode: normal;
    max-width: 416px;
    @media only screen and (max-width: 750px) {
      font-size: 16px;
    }
  }
  a {
    font-family: 'Poppins-Bold';
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    text-decoration-line: underline;
    font-feature-settings: 'ss02' on;
    color: #000000;
    @media only screen and (max-width: 750px) {
      font-size: 16px;
    }
  }
`

const Title = styled.h1`
  font-family: 'Poppins-Medium';
  font-weight: 500;
  font-size: 70px;
  line-height: 115%;
  max-width: 370px;
  color: #282828;
  @media only screen and (max-width: 750px) {
    font-size: 50px;
  }
  img {
    padding-left: 37px;
  }
  .orange {
    color: #ff6700;
    text-decoration-line: underline;
    text-underline-offset: 7px;
    text-decoration-thickness: 4px;
  }
`

export default Hero
