import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Carousel from 'react-elastic-carousel'
import { Parallax } from 'react-scroll-parallax'
import star from '../assets/star.svg'
import starb from '../assets/black-star.svg'
import { Container } from '../styles/texts'
import TitleBox from '../components/TitleBox'
import ringL from '../assets/rings-left.svg'
import ringR from '../assets/rings-right.svg'
import { FormattedMessage, useIntl } from 'react-intl'

const Features = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])
  const medium = 750
  const boxes = (
    <>
      <div key={1}>
        <TitleBox>NFT’s MARKET PLACE</TitleBox>
        <p>
          <FormattedMessage id="nft" />
        </p>
      </div>
      <div key={2}>
        <TitleBox>DOC CERTIFICATION</TitleBox>
        <p>
          <FormattedMessage id="doc" />
        </p>
      </div>
      <div key={3}>
        <TitleBox>CRYPTO ART</TitleBox>
        <p>
          <FormattedMessage id="crypto" />
        </p>
      </div>
      <div key={4}>
        <TitleBox>WORLD CUP NFT’s</TitleBox>
        <p>
          <FormattedMessage id="world" />
        </p>
      </div>
      <div key={5}>
        <TitleBox>GAMIFICATION</TitleBox>
        <p>
          <FormattedMessage id="game" />
        </p>
      </div>
      <div key={6}>
        <TitleBox>LOYALTY</TitleBox>
        <p>
          <FormattedMessage id="loyal" />
        </p>
      </div>
    </>
  )
  return (
    <>
      <FeaturesSection>
        {width >= medium && (
          <Parallax speed={15}>
            <RingR src={ringR} />
          </Parallax>
        )}
        {width >= medium ? (
          <StyledContainer>{boxes}</StyledContainer>
        ) : (
          <Carousel>
            <div key={1}>
              <TitleBox>NFT’s MARKET PLACE</TitleBox>
              <p>
                Create your own NFT trading platform to display, sell and trade
                your tokens faster.
              </p>
            </div>
            <div key={2}>
              <TitleBox>DOC CERTIFICATION</TitleBox>
              <p>
                Certify your documents on a blockchain ledger and get your
                immutable hash as proof of register.
              </p>
            </div>
            <div key={3}>
              <TitleBox>CRYPTO ART</TitleBox>
              <p>
                Bring to life your artwork and insert them into the Metaverse &
                VR world.
              </p>
            </div>
            <div key={4}>
              <TitleBox>WORLD CUP NFT’s</TitleBox>
              <p>
                Become a sticker album and world cup collectibles maker in a few
                simple steps.
              </p>
            </div>
            <div key={5}>
              <TitleBox>GAMIFICATION</TitleBox>
              <p>
                Build your play & earn Dapp, integrate it with multiple cryptos
                and surf the gamming wave.
              </p>
            </div>
            <div key={6}>
              <TitleBox>LOYALTY</TitleBox>
              <p>
                Design your own loyalty program. Define your token type,
                airdrops, accummulation and redemption rules.
              </p>
            </div>
          </Carousel>
        )}

        {width >= medium && (
          <Parallax speed={15}>
            <RingL src={ringL} />
          </Parallax>
        )}
      </FeaturesSection>
      {width >= medium && <Star />}
    </>
  )
}

const Star = styled.section`
  background-image: url(${starb}),
    linear-gradient(to top, #faff00 0%, #faff00 49%, #ffffff 49%, #ffffff 100%);
  background-position: 50% 51%;
  background-repeat: no-repeat;
  height: 50px;
`

const FeaturesSection = styled.section`
  background: white;
  height: auto;
  color: black;
  padding-bottom: 60px;
  position: relative;
  @media only screen and (max-width: 700px) {
    padding-top: 80px;
  }
  p {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 134.5%;
    font-feature-settings: 'ss02' on;
    color: #000000;
    padding: 5px 20px;
    max-width: 300px;
  }
  .rec-dot {
    border: 2px solid black;

    box-shadow: none;
    border-radius: 0;
  }
  .rec-dot_active {
    background-color: black;
  }
  .rec-arrow {
    display: none;
  }
`
const RingL = styled.img`
  position: absolute;
  left: 0;
  bottom: 90px;
`
const RingR = styled.img`
  position: absolute;
  right: 0;
  top: 0px;
`

const StyledContainer = styled(Container)`
  height: auto;
  max-width: 1110px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 50px;
  background: white;

  @media only screen and (min-width: 751px) and (max-width: 1170px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    max-width: 790px;
  }
`

export default Features
