import 'react-app-polyfill/ie9';
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Nft from './pages/Nft'
import Ledger from './pages/Ledger'
import ScrollToTop from './components/ScrollToTop'
import reportWebVitals from './reportWebVitals'
import { ParallaxProvider } from 'react-scroll-parallax'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { addLocaleData } from 'react-intl'


import messages_en from './translations/en.json'
import messages_es from './translations/es.json'

const root = ReactDOM.createRoot(document.getElementById('root'))
const messages = {
  es: messages_es,
  en: messages_en,
}

let defaultLanguage = 'en'
const locale =
  typeof window.localStorage !== 'undefined'
    ? localStorage.getItem('locale')
    : 'en'


root.render(
  <React.StrictMode>
  <IntlProvider
    locale={locale || defaultLanguage}
    messages={messages[locale || defaultLanguage]}
  >
    <ParallaxProvider>
      <HashRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/">
            <Route index element={<App />} />
            <Route path="nft" element={<Nft />} />
            <Route path="ledger-manager" element={<Ledger />} />
          </Route>
        </Routes>
      </HashRouter>
    </ParallaxProvider>
    </IntlProvider>
  </React.StrictMode>
)

reportWebVitals()
