import React from 'react'
import Faq from 'react-faq-component'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import arrow from '../assets/module-arrow.svg'
import star from '../assets/black-star.svg'
import { Container } from '../styles/texts'
import Loop from '../components/Loop'
import { FormattedMessage, useIntl } from 'react-intl'

const Modules = () => {
  const intl = useIntl()
  const data = {
    rows: [
      {
        title: (
          <p>
            <span className="big">state machine</span>
            <span className="soon small">SOON</span>
            <span className="small">Q3 _ 2023</span>
          </p>
        ),
        content: intl.formatMessage({ id: 'modules.machine' }),
      },
      {
        title: (
          <p>
            <span className="big">swapper</span>
            <span className="soon small">SOON</span>
            <span className="small">Q3 _ 2023</span>
          </p>
        ),
        content: intl.formatMessage({ id: 'modules.swapper' }),
      },
      {
        title: (
          <p>
            <span className="big">check out</span>
            <span className="soon small">SOON</span>
            <span className="small">Q3 _ 2023</span>
          </p>
        ),
        content: intl.formatMessage({ id: 'modules.check' }),
      },
      {
        title: (
          <p>
            <span className="big">gas station</span>
            <span className="soon small">SOON</span>
            <span className="small">Q3 _ 2023</span>
          </p>
        ),
        content: intl.formatMessage({ id: 'modules.gas' }),
      },
      {
        title: (
          <p>
            <span className="big">CUSTODIAL WALLETS</span>
            <span className="soon small">SOON</span>
            <span className="small">Q3 _ 2023</span>
          </p>
        ),
        content: intl.formatMessage({ id: 'modules.custodial' }),
      },
    ],
  }

  return (
    <ModulesSection>
      <ModulesContainer>
        <Loop
          reverse={true}
          content={
            <Title>
              <h1>
                <FormattedMessage id="modules.title" />
              </h1>
              <img alt="" src={star} />
              <h1>
                <FormattedMessage id="modules.title" />
              </h1>
              <img alt="" src={star} />
              <h1>
                <FormattedMessage id="modules.title" />
              </h1>
              <img alt="" src={star} />
              <h1>
                <FormattedMessage id="modules.title" />
              </h1>
              <img alt="" src={star} />
              <h1>
                <FormattedMessage id="modules.title" />
              </h1>
              <img alt="" src={star} />
              <h1>
                <FormattedMessage id="modules.title" />
              </h1>
              <img alt="" src={star} />
              <h1>
                <FormattedMessage id="modules.title" />
              </h1>
              <img alt="" src={star} />
            </Title>
          }
        />
        <StyledLink to="/ledger-manager">
          <div>
            <span className="big">Ledger</span>{' '}
            <span className="small">100%</span>
          </div>
          <img alt="" src={arrow} />
        </StyledLink>
        <StyledLink to="/nft">
          <div>
            <span className="big">Nft</span> <span className="small">100%</span>
          </div>
          <img alt="" src={arrow} />
        </StyledLink>

        <Faq
          data={data}
          styles={{
            bgColor: 'transparent',
            titleTextColor: 'black',
            rowTitleColor: 'black',
            rowTitleTextSize: 'large',
            rowContentColor: 'black',
            rowContentTextSize: '16px',
            rowContentPaddingTop: '24px',
            rowContentPaddingBottom: '24px',
            rowContentPaddingLeft: '32px',
            rowContentPaddingRight: '32px',
            arrowColor: 'black',
            zIndex: '999',
          }}
          config={{
            animate: true,
            arrowIcon: <img alt="" src={arrow} />,
          }}
        />
      </ModulesContainer>
    </ModulesSection>
  )
}

const StyledLink = styled(Link)`
  padding: 24px 64px;

  border-bottom: solid 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 750px) {
    padding: 24px 30px 24px 20px;
    img {
      height: 30px;
    }
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  border-top: solid 2px black;
  border-bottom: solid 2px black;
  @media only screen and (max-width: 750px) {
    img {
      height: 30px;
      margin: auto;
    }
  }
  h1 {
    margin: 20px 30px;
    font-family: 'Poppins-Thin';
    font-size: 70px;
    line-height: 105px;
    text-transform: uppercase;
    @media only screen and (max-width: 750px) {
      font-size: 32px;
      line-height: 49px;
    }
  }
`

const ModulesSection = styled.section`
  background: white;
  height: auto;
  color: black;
  width: 100vw;
  overflow-x: hidden;
`
const ModulesContainer = styled(Container)`
  padding: 0;
  margin: 0;
  .faq-row {
    padding: 0 64px;
    border-color: black;
    border-bottom: 2px solid;
    @media only screen and (max-width: 750px) {
      padding: 0 20px;
      img {
        height: 30px;
      }
    }
  }
  .icon-wrapper {
    position: relative !important;
    top: unset !important;
    max-height: 55px !important;
  }
  .row-title {
    &.expanded {
      .icon-wrapper {
        transform: rotate(-90deg) !important;
      }
    }
    &.expanding {
      .icon-wrapper {
        transform: rotate(-90deg) !important;
      }
    }
  }
  .big {
    padding: 0 20px 0 0px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 65px;
    line-height: 98px;
    text-transform: uppercase;
    color: #000000;
    @media only screen and (max-width: 750px) {
      font-size: 28px;
      line-height: 42px;
      padding: 0 7px 0 0px;
    }
  }
  .small {
    font-size: 26px;
    line-height: 40px;
    @media only screen and (max-width: 750px) {
      font-size: 13px;
      line-height: 19px;
    }
  }
  .soon {
    color: #ff6700;
    padding-right: 7px;
    font-family: 'Poppins-Bold';
    @media only screen and (max-width: 750px) {
      padding-right: 3px;
    }
  }
`

export default Modules
