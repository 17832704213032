import Hero from './sections/Hero'
import Banner from './sections/Banner'
import Navbar from './components/Navbar'
import Modules from './sections/Modules'
import Subscribe from './sections/Subscribe'
import Description from './sections/Description'
import Features from './sections/Features'
import FeatureSubtitle from './sections/FeatureSubtitle'
import Footer from './sections/Footer'
import WhatYouCanDo from './sections/WhatYouCanDo'


function App() {
  return (
      <div>
        <Navbar />
        <Hero />
        <Banner/>
        <FeatureSubtitle />
        <WhatYouCanDo />
        <Description />
        <Features />
        <Modules/>
        <Subscribe />
        <Footer />
      </div>
  )
}

export default App
