import React from 'react'
import styled from 'styled-components'
import { Container } from '../styles/texts'
import bloques from '../assets/bloques.gif'
import { FormattedMessage, useIntl } from 'react-intl'

const Description = () => (
  <DescriptionSection id="description">
    <SContainer>
      <DescriptionContainer>
        <div>
          <h2>
            <FormattedMessage id="description.title" />
          </h2>
          <p>
            <FormattedMessage id="description.text" />
          </p>
        </div>
        <div>
          <img alt="" src={bloques} />
        </div>
      </DescriptionContainer>
    </SContainer>
  </DescriptionSection>
)

const DescriptionSection = styled.section`
  background: #faff00;
  height: auto;
  color: #1e1e1e;
  font-family: 'Poppins';
  font-weight: 400!important;
  h2 {
    font-size: 30px;
    line-height: 45px;
    font-weight: 400!important;
  }
  p {
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'ss01' on, 'ss02' on;
  }
`

const SContainer = styled(Container)`
  padding: 64px 0;
  height: auto;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  div {
    max-width: 700px;
    overflow: hidden;
  }
  img {
    width: 550px;
    @media only screen and (max-width: 750px) {
      width: 400px;
    }
  }
`

export default Description
