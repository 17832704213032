import React from 'react'
import styled from 'styled-components'
import Navbar from '../components/Navbar'
import Footer from '../sections/Footer'
import star from '../assets/star-orange.svg'
import buttons from '../assets/buttons.gif'
import nft from '../assets/nft.gif'

const Nft = () => {
  return (
    <div>
      <Navbar />
      <NftSection>
        <NftContainer>
          <div>
            <Title>NFT.</Title>
            <h2>
              Design, create and mint your own NFTs. Unleash the creators
              economy.
            </h2>
            <a href="#description">suscribe now</a>
          </div>
          <img alt="" src={nft} />
        </NftContainer>
        <BoxContainer>
          <Box>
            <div className="flex-row">
              <div className="right-outline border" />
              <div className="box border">
                <div>
                  <img alt="" width={80} src={buttons} />
                  <p>
                    As the NFTs are only owned by the one who creates them, it’s
                    creation will be free if a non custodial approach is
                    selected providing only the documentation needed to create a
                    transaction to create it in the chosen blockchain.
                  </p>
                  <img alt="" src={star} />
                </div>
              </div>
            </div>
            <div className="bottom-outline border" />
          </Box>
        </BoxContainer>
        <BoxContainer>
          <h3>
            <span>It provides the ability to add state</span> <br />
            to these NFTs allowing it to represent more complex systems such as
          </h3>
        </BoxContainer>
        <Features>
          <div>LEASING</div>
          <div>PRODUCT CRAFTING STAGES</div>
          <div>WARRANTIES</div>
        </Features>
        <Footer />
      </NftSection>
    </div>
  )
}

const Features = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 150px;
  height: 110px;
  border-top: solid 2px #ff6700;
  border-bottom: solid 2px #ff6700;
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 51px;
  color: #ff6700;

  @media only screen and (max-width: 1060px) {
    font-size: 20px;
    line-height: 103%;
    height: 60px;
  }
  @media only screen and (max-width: 750px) {
    font-size: 14px;
    line-height: 103%;
    flex-wrap: wrap;
  }
  div {
    height: 100%;
    padding 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  div:nth-child(2) {
    border-right: solid 2px #ff6700;
    border-left: solid 2px #ff6700;

    @media only screen and (max-width: 750px) {
      min-width: 200px!important;
      border-right: 0;
      border-left: 0;
      border-bottom: solid 2px #ff6700;
    }
    min-width: 517px;
  }
  div:nth-child(3) {
    @media only screen and (max-width: 750px) {
      border-bottom: solid 2px #ff6700;
    }
  }
`

const NftSection = styled.section`
  height: auto;
  background: white;
  padding-top: 80px;
  @media only screen and (max-width: 700px) {
    min-height: 650px;

    padding: 60px 0 0 0;
  }
  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    color: #000000;
    span {
      font-family: 'Poppins-Bold';
      font-weight: 600;
    }
  }
`

const Box = styled.div`
  height: auto;

  .border {
    border: 2px solid #ff6700;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .box {
    width: 100%;
    height: auto;
    padding: 15px 30px;
    @media only screen and (max-width: 750px) {
      padding: 10px 15px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 39px;
    max-width: 100%;
    color: #000000;
    margin: 50px 0;
    @media only screen and (max-width: 750px) {
      font-size: 16px;
      line-height: 24px;
      margin: 20px 0;
    }
  }

  .bottom-outline {
    width: calc(100% - 16px);

    height: 12px;
    border-top-width: 0px !important;
    transform: skew(-45deg) translate(6px, 0);
  }

  .right-outline {
    width: 12px;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    transform: skew(0deg, -45deg) translate(0, 6px);
  }
`

const BoxContainer = styled.div`
  display: block;
  height: auto;
  max-width: 960px;
  padding: 30px 96px;
  @media only screen and (max-width: 750px) {
    padding: 30px;
  }
`

const NftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 96px;
  @media only screen and (max-width: 750px) {
    padding: 30px;
  }

  img {
    max-width: 55%;
    @media only screen and (max-width: 750px) {
      max-width: 69%;
      align-self: flex-end;
    }
  }
  @media only screen and (max-width: 750px) {
    padding: 0 20px;
    flex-direction: column-reverse;
  }
  div {
    max-width: 700px;
    @media only screen and (max-width: 750px) {
      max-width: 90%;
      margin: auto;
    }
  }

  h2 {
    font-family: 'Poppins-Thin';
    font-size: 26px;
    line-height: 129%;
    font-feature-settings: 'ss02' on;
    color: #20201e;
    max-width: 500px;
    @media only screen and (max-width: 750px) {
      font-size: 16px;
    }
  }
  a {
    font-family: 'Poppins-Bold';
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ff6700;
    padding: 8px 45px;
    border: solid 2px #ff6700;
    display: inline-block;
    margin-top: 40px;
  }
`

const Title = styled.h1`
  padding-top: 150px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  font-size: 65px;
  line-height: 115%;
  max-width: 370px;
  color: #282828;
  @media only screen and (max-width: 750px) {
    font-size: 50px;
    padding: 0;
    margin-top: -100px;
  }
`

export default Nft
