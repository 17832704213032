import React from 'react'
import styled from 'styled-components'

const TitleBox = ({ children }) => (
  <Box>
    <div className="flex-row">
      <div className="right-outline border" />
      <div className="box border">{children}</div>
    </div>
    <div className="bottom-outline border" />
  </Box>
)

const Box = styled.div`
  height: 100px;

  .border {
    border: 4px solid black;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .box {
    width: 300px;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins-Bold';
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    color: #000000;
  }

  .bottom-outline {
    width: 300px;
    height: 12px;
    border-top-width: 0px !important;
    transform: skew(-45deg) translate(8px, 0);
  }

  .right-outline {
    width: 12px;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    transform: skew(0deg, -45deg) translate(0, 8px);
  }
  &:hover {
    cursor: default; 
    .right-outline,
    .bottom-outline {
      display: none;
    }
    .box {
      transform: translateY(8px);
    }
  }
`

export default TitleBox
