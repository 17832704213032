import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo.svg'
import { Container } from '../styles/texts'

const Footer = () => (
  <FooterSection>
    <FContainer>
      <FooterContainer>
        <div>
          <a href="https://www.koibanx.com" rel="noreferrer" target="_blank">
            <img alt="" src={logo} />
          </a>
        </div>
        <div>
          <Link
            href="https://linktr.ee/koibanxpolicy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policies
          </Link>
          <Link
            href="https://koibanx-webpage-terminos.s3.amazonaws.com/T%C3%A9rminos+de+Uso+-+Koibanx+El+Salvador+2022.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions El Salvador
          </Link>
        </div>
        <div>
          <Button href="www.koibanx.com/jobs" rel="noreferrer" target="_blank">
            Join our team
          </Button>
          <Button
            href="mailto:info@koibanx.com"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </Button>
        </div>
      </FooterContainer>
    </FContainer>
  </FooterSection>
)

const FooterSection = styled.section`
  background: #131313;
  height: auto;
  color: white;
`

const FContainer = styled(Container)`
  padding: 64px 0;
  height: auto;
`

const FooterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    display: flex;
    gap: 30px;
  }
  @media only screen and (max-width: 420px) {
    div {
      width: 90%!important;
    }
  }
  @media only screen and (max-width: 1130px) {
    flex-direction: column;
    div {
      padding: 20px 0;
      text-align: justify;
      width: 367px;
      margin: auto;
      justify-content: space-around;
    }
  }
`

const Link = styled.a`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #ffffff;
  @media only screen and (max-width: 750px) {
    font-size: 11px;
    line-height: 19px;
  }
`

const Button = styled.a`
  font-family: 'Poppins-Bold';
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding: 8px 30px;
  border: 2px solid #ffffff;
  @media only screen and (max-width: 750px) {
    font-size: 11px;
    line-height: 18px;
    padding: 6px 20px;
  }
`

export default Footer
