import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl'

const FeatureSubtitle = () => {
  return (
    <FeatureSubtitleSection>
      <FeatureSubtitleContainer>
        <Title>
          <FormattedMessage id="features.explanation" />
        </Title>
      </FeatureSubtitleContainer>
    </FeatureSubtitleSection>
  )
}

const FeatureSubtitleSection = styled.section`
  height: auto;
  padding: 0 30px 30px 30px;
  background: white;
  @media only screen and (max-width: 700px) {
    background: #faff00;
    padding: 30px;
  }
`

const FeatureSubtitleContainer = styled.div`
  max-width: 1110px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (min-width: 701px) and (max-width: 1150px) {
    max-width: 90%;
  }
  @media only screen and (max-width: 700px) {
    justify-content: center;
  }
`

const Title = styled.h1`
  font-family: 'Poppins-Bold';
  font-size: 24px;
  line-height: 35px;
  max-width: 480px;
  color: #262626;
  @media only screen and (max-width: 700px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export default FeatureSubtitle
