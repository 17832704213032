import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 64px;

  @media only screen and (min-width: 701px) and (max-width: 1200px) {

    max-width: 1200px;
  }
  @media only screen and (min-width: 1900px) {
    margin: auto;
  }
  @media only screen and (max-width: 700px) {
    margin: 0 24px;
    padding 24px 0;
  }

`
