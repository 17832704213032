import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import star from '../assets/star.svg'
import Loop from '../components/Loop'
import { FormattedMessage, useIntl } from 'react-intl'

const Features = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])
  const medium = 750
  return (
    <FeaturesSection>
      <StyledContainer>
        <div className="item-a">
          <p><FormattedMessage id="feature.title" /></p>
        </div>
        {width >= medium ? (
          <div className="item-b">
            <div>
              <h6>Api rest</h6>
              <img alt="" src={star} />
            </div>
            <div>
              <h6>Documentation</h6>
              <img alt="" src={star} />
            </div>
            <div>
              <h6>Sdk</h6>
              <img alt="" src={star} />
            </div>
          </div>
        ) : (
          <Loop
            reverse={true}
            content={
              <Title>
                <h6>API REST</h6>
                <img alt="" src={star} />
                <h6>DOCUMENTATION</h6>
                <img alt="" src={star} />
                <h6>SDK</h6>
                <img alt="" src={star} />
                <h6>API REST</h6>
                <img alt="" src={star} />
                <h6>DOCUMENTATION</h6>
                <img alt="" src={star} />
                <h6>SDK</h6>
                <img alt="" src={star} />
                <h6>API REST</h6>
                <img alt="" src={star} />
                <h6>DOCUMENTATION</h6>
                <img alt="" src={star} />
                <h6>SDK</h6>
                <img alt="" src={star} />
              </Title>
            }
          />
        )}

        <div className="item-c">
          <h6><FormattedMessage id="feature.text" /></h6>
        </div>
      </StyledContainer>
    </FeaturesSection>
  )
}

const Title = styled.div`
  display: flex;
  background: black;
  align-items: center;
  margin-top: 30px;
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  padding: 20px 0;
  h6 {
    padding: 0 20px;
  }
  @media only screen and (max-width: 750px) {
    img {
      height: 13px;
      margin: auto;
    }
  }
`

const FeaturesSection = styled.section`
  background: black;
  height: auto;
  color: white;
  width: 100vw;
  overflow-x: hidden;

`
const StyledContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 240px 170px;
  grid-template-areas:
    'item-a item-b item-b'
    'item-a item-c item-c';
  gap: 1px 1px;
  background: white;
  @media only screen and (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    background: black;
    gap: 0;
    grid-template-areas:
      'item-a '
      'item-b'
      'item-c';
  }

  .item-a {
    grid-area: item-a;
    background: black;
    justify-items: stretch;
    align-items: stretch;
    p {
      font-family: 'Poppins-Medium';
      font-weight: 500;
      font-size: 50px;
      line-height: 97%;
      font-feature-settings: 'ss01' on, 'ss02' on;
      color: #fff5f0;
      max-width: 300px;
      @media only screen and (max-width: 750px) {
        font-size: 30px;
      }
    }
    padding: 64px;
    @media only screen and (max-width: 750px) {
      padding: 20px;
    }
  }
  .item-b {
    grid-area: item-b;
    justify-items: stretch;
    align-items: stretch;
    display: grid;
    grid-template-columns: auto 2fr 2fr;
    gap: 1px;
    div {
      background: black;
      padding: 40px;
      text-align: right;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
    }
  }
  .item-c {
    grid-area: item-c;
    background: black;
    justify-items: stretch;
    align-items: stretch;
    padding: 35px;
    @media only screen and (max-width: 750px) {
      padding: 35px 20px;
    }
  }
  h6 {
    margin: 0;
    font-family: 'Poppins-Light';
    font-weight: 300;
    font-size: 30px;
    line-height: 45px;
    text-transform: uppercase;
    font-feature-settings: 'ss01' on, 'ss02' on;
    color: #fff5f0;
    @media only screen and (max-width: 750px) {
      font-size: 20px;
      line-height: 35px;
      max-width: 330px;
    }
  }
`

export default Features
