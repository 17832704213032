import React from 'react'
import styled from 'styled-components'
import star from '../assets/black-star.svg'
import banner from '../assets/banner.svg'

const Banner = () => {
  return (
    <BannerSection>
      <BannerContainer>
        <Title>
          <img alt="" src={banner} />
          subscribe <br />
          <div />
          sign up <br /> <div />
          <div />
          start building.
        </Title>
      </BannerContainer>
    </BannerSection>
  )
}

const BannerSection = styled.section`
  height: auto;
  padding: 30px 30px 0 30px;
  background-image: url(${star}),
    linear-gradient(to top, #ffffff 0%, #ffffff 49%, #faff00 49%, #faff00 100%);
  background-position: 50% 51%;
  background-repeat: no-repeat;
  @media only screen and (max-width: 700px) {
    background-image: linear-gradient(
      to top,
      #ffffff 0%,
      #ffffff 49%,
      #faff00 49%,
      #faff00 100%
    );
  }
`

const BannerContainer = styled.div`
  max-width: 1110px;
  margin: auto;
  @media only screen and (min-width: 701px) and (max-width: 1150px) {
    max-width: 90%;
  }
  @media only screen and (max-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Title = styled.h1`
  font-family: 'Poppins-Thin';
  font-size: 66px;
  line-height: 113%;
  text-transform: lowercase;
  color: #262626;
  mix-blend-mode: normal;
  margin: 0;

  @media only screen and (max-width: 750px) {
    font-size: 37px;
    margin: 40px 0;
  }
  div {
    display: inline-block;
    width: 70px;
    @media only screen and (max-width: 750px) {
      width: 70px;
    }
  }
  img {
    margin-left: -40px;
    margin-right: 40px;
    @media only screen and (max-width: 750px) {
      height: 20px;
      margin-left: -20px;
      margin-right: 20px;
    }
  }
`

export default Banner
