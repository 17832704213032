import React from 'react'
import styled from 'styled-components'
import { Container } from '../styles/texts'
import { FormattedMessage, FormattedHTMLMessage, } from 'react-intl'

const Subscribe = () => (
  <SubscribeSection>
    <SContainer>
      <SubscribeContainer>
        <div>
          <h2><FormattedMessage id="suscribe.title"/></h2>
        </div>
        <div>
          <p>
            <FormattedHTMLMessage id="suscribe.text" />
          </p>
          <Button href="mailto:info@koibanx.com"><FormattedMessage id="suscribe.mail" /></Button>
        </div>
      </SubscribeContainer>
    </SContainer>
  </SubscribeSection>
)

const SubscribeSection = styled.section`
  background: #faff00;
  height: auto;
  color: black;
  h2 {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 65px;
    line-height: 98px;
    text-align: right;

    text-transform: lowercase;
    font-feature-settings: 'ss04' on, 'ss02' on, 'ss01' on;
    text-align: right;
    color: #000000;
    text-decoration-line: underline;
    text-underline-offset: 7px;
    text-decoration-thickness: 4px;
    mix-blend-mode: normal;
    max-width: 780px;
    @media only screen and (max-width: 750px) {
      max-width: 468px;
      text-align: left;
      font-size: 40px;
      line-height: 60px;
      margin-top: 0;
    }
  }
  p {
    width: 260px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'ss02' on;
    color: #000000;
    margin-bottom: 30px;
    span.bold {
      font-family: 'Poppins-Bold';
      text-decoration-line: underline;
    }
    @media only screen and (max-width: 750px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
`

const SContainer = styled(Container)`
  padding: 64px 0;
  height: auto;
`

const SubscribeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Button = styled.a`
  font-family: 'Poppins-Bold';
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000;
  padding: 8px 30px;
  border: 2px solid #000;
`

export default Subscribe
