import React, { useState } from 'react'
import styled from 'styled-components'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import { Buffer } from 'buffer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl'

// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer
}

const Form = () => {
  const [formData, setFormData] = useState({})

  //Import environmental varialbles
  const {
    REACT_APP_PRIVATE_KEY,
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_SPREADSHEET_ID,
    REACT_APP_SHEET_ID,
  } = process.env

  //creating new object of google spreadsheet
  const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID)

  //Function append spreadsheet to add row into google sheet

  const appendSpreadsheet = async row => {
    console.log(REACT_APP_CLIENT_EMAIL)
    const id = toast.loading("Please wait...")
    try {
      await doc.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      })
      await doc.loadInfo()
      const sheet = doc.sheetsById[REACT_APP_SHEET_ID]
      console.log('sheet', sheet)
      const result = await sheet.addRow(row)
      return result
    } catch (e) {
      console.error('Error: ', e)
      toast.update(id, { render: "There was an error. Plese try again", type: "error", isLoading: false });

    } finally {
      toast.update(id, { render: "Thank you. We will get in contact with you", type: "success", isLoading: false });
      setTimeout(() => toast.dismiss(), 2000);

    }
  }

  //end

  const handleInputChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    appendSpreadsheet(formData)
  }

  return (
    <StlyedForm>
      <ToastContainer />
      <p>
        <FormattedHTMLMessage id="form.explanation" />
      </p>
      <form className="App" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name*"
          onChange={handleInputChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="E-mail*"
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="country"
          placeholder="Country*"
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="project"
          placeholder="Project idea"
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="profile"
          placeholder="Github / Gitlab profile"
          onChange={handleInputChange}
        />
        <Button type="submit">subscribe now</Button>
      </form>
    </StlyedForm>
  )
}

const Button = styled.button`
  margin: auto;
  font-family: 'Poppins-Bold';
  background: transparent;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: black;
  padding: 8px 30px;
  border: 2px solid black;
  @media only screen and (max-width: 750px) {
    font-size: 11px;
    line-height: 18px;
    padding: 6px 20px;
  }
`

export const StlyedForm = styled.nav`
  text-align: center;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 351px;
    font-feature-settings: 'ss02' on;
    color: #000000;
    mix-blend-mode: normal;
    b {
      font-family: 'Poppins-Bold';
      font-weight: 600;
    }
  }
  input {
    display: block;
    border: 0;
    margin: 20px 0;
    background: #282828;
    color: white;
    padding: 10px 25px;
    width: 320px;
    max-width: 85%;
    ::placeholder {
      color: white;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: white;
    }

    ::-ms-input-placeholder {
      color: white;
    }
  }
`

export default Form
